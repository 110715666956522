import React from "react";
import styled from "styled-components";
import { Container, Row, Col, Nav, Tab } from "react-bootstrap";
import { Section, Title, Text, Box } from "../components/Core";

import PageWrapper from "../components/PageWrapper";
import SignUpVideo from "../assets/video/signup.webm";
import SignInVideo from "../assets/video/sign_in.webm";
import NewProjectVideo from "../assets/video/new_project.webm";
import AddNewManagerVideo from "../assets/video/new_manager.webm";
import AddSlackToken from "../assets/video/add_slack_token.webm";
import AddSlackBot from "../assets/video/add_slack_bot.webm";

const NavStyled = styled(Nav)`
  border-radius: 10px;
  border: 1px solid #eae9f2;
  background-color: #f7f7fb;
  padding-top: 15px;
  padding-bottom: 15px;
  a {
    color: ${({ theme }) => theme.colors.dark} !important;
    &:hover,
    &:active,
    &:visited {
      color: ${({ theme }) => theme.colors.secondary} !important;
    }
  }
`;

const Help = () => {
  return (
    <>
      <PageWrapper footerDark>
        <Section className="pb-0">
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="9">
                <Title variant="hero">How to section</Title>
                <Text>
                  Here you can find help videos, about FAQ.
                </Text>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section>
          <Container>
            <Tab.Container id="left-tabs-example" defaultActiveKey="general">
              <Row>
                <Col md="4" className="mb-5 mb-md-0">
                  <NavStyled className="flex-column mr-md-5">
                    <Nav.Link eventKey="signup">How to sign up</Nav.Link>
                    <Nav.Link eventKey="sign-in">Hot to sign in</Nav.Link>
                    <Nav.Link eventKey="new-project">How to add a project</Nav.Link>
                    <Nav.Link eventKey="new-manager">How to add a manager</Nav.Link>
                    <Nav.Link eventKey="slack-token">How to add a slack token</Nav.Link>
                    <Nav.Link eventKey="slack-bot">How to add a slack bot</Nav.Link>
                  </NavStyled>
                </Col>
                <Col md="8">
                  <Tab.Content>
                    <Tab.Pane eventKey="signup">
                      <Box>
                        <Box mb={4}>
                          <Title variant="card" mb={3} fontSize="24px">
                            How to sign up on Fisherman App?
                          </Title>
                          <Text variant="small">
                            Creating a new Fisherman account only requires
                            a valid email address and password.
                          </Text>
                          <Box m={4}>
                            <video controls>
                              <source src={SignUpVideo} type="video/webm" />
                              <track src="" kind="captions" srclang="en" label="english_captions"></track>
                            </video>
                          </Box>
                        </Box>
                      </Box>
                    </Tab.Pane>
                    <Tab.Pane eventKey="sign-in">
                      <Box>
                        <Box mb={4}>
                          <Title variant="card" mb={3} fontSize="24px">
                            How to sign in on Fisherman App?
                          </Title>
                          <Text variant="small">
                            To sign in to your Fisherman account, 
                            you must provide your email and password 
                            from previously completed the signup process.
                          </Text>
                          <Box m={4}>
                            <video controls>
                              <source src={SignInVideo} type="video/webm" />
                              <track src="" kind="captions" srclang="en" label="english_captions"></track>
                            </video>
                          </Box>
                        </Box>
                      </Box>
                    </Tab.Pane>
                    <Tab.Pane eventKey="new-project">
                      <Box>
                        <Box mb={4}>
                          <Title variant="card" mb={3} fontSize="24px">
                            Creating a new project.
                          </Title>
                          <Text variant="small">
                            Create a new project in three simple steps,
                            select a company that will host the project,
                            click on New Project, and provides a project name.
                          </Text>
                          <Box m={4}>
                            <video controls>
                              <source src={NewProjectVideo} type="video/webm" />
                              <track src="" kind="captions" srclang="en" label="english_captions"></track>
                            </video>
                          </Box>
                        </Box>
                      </Box>
                    </Tab.Pane>
                    <Tab.Pane eventKey="new-manager">
                      <Box>
                        <Box mb={4}>
                          <Title variant="card" mb={3} fontSize="24px">
                            How to add a new manager to a specific project?
                          </Title>
                          <Text variant="small">
                            You can add as many managers as you need
                            to any project on your Fisherman App.
                          </Text>
                          <Box m={4}>
                            <video controls>
                              <source src={AddNewManagerVideo} type="video/webm" />
                              <track src="" kind="captions" srclang="en" label="english_captions"></track>
                            </video>
                          </Box>
                        </Box>
                      </Box>
                    </Tab.Pane>
                    <Tab.Pane eventKey="slack-token">
                      <Box>
                        <Box mb={4}>
                          <Title variant="card" mb={3} fontSize="24px">
                            How to add a new slack token?
                          </Title>
                          <Text variant="small">
                            To connect Fisherman with your
                            slack channels bots. This video
                            will guide you through the process.
                          </Text>
                          <Box m={4}>
                            <video controls>
                              <source src={AddSlackToken} type="video/webm" />
                              <track src="" kind="captions" srclang="en" label="english_captions"></track>
                            </video>
                          </Box>
                        </Box>
                      </Box>
                    </Tab.Pane>
                    <Tab.Pane eventKey="slack-bot">
                      <Box>
                        <Box mb={4}>
                          <Title variant="card" mb={3} fontSize="24px">
                            How to add a new slack bot?
                          </Title>
                          <Text variant="small">
                            Creating a new slack bot, you can create
                            as many bots as you needed, follow next 
                            video steps to learn how to do it.
                          </Text>
                          <Box m={4}>
                            <video controls>
                              <source src={AddSlackBot} type="video/webm" />
                              <track src="" kind="captions" srclang="en" label="english_captions"></track>
                            </video>
                          </Box>
                        </Box>
                      </Box>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};
export default Help;
